/* eslint-disable */

/**
 * @file
 *   JavaScript for the OptionPlan.
 */

import Vue from 'vue'
import $ from 'jquery'
import { debounce, throttle } from 'lodash'
import { ivShare } from 'app/handbooks/ui/js/iv-share'
import { ivSimpleChart } from './iv-simple-chart'
import { ivBarChart } from './iv-bar-chart'
import VueSlider from 'vue-slider-component'
import { Radio as VueRadio } from 'vue-checkbox-radio'
import { Money } from 'v-money'
import OptionplanConfig from './optionplan.config'
import stickybits from 'stickybits'
import tippy from 'tippy.js'
import attachSocialShareEvents from "../../../ui/js/utils/attachSocialShareEvents";
import Bugsnag from "@bugsnag/js";
import bugsnagConfig from "../../../ui/js/utils/bugsnagConfig";
import * as Fathom from 'fathom-client';
import Cookies from 'js-cookie'

/**
* Finds the mode from the URL hash
* @param {string} hash
* @returns {string | null}
*/
const getModeFromUrl = () => {
    const match = window.location.hash.match(/&mode=(\w+)/)
    return match ? match[1] : null
}

const getUahAndExpiryKeys = (mode = null) => {
    const foundMode = mode || getModeFromUrl()
    const allowedModes = ['seed', 'venture']
    if (foundMode && allowedModes.includes(foundMode))
        return [`OP_UAH_${foundMode}`, `OP_UAH_E_${foundMode}`]

    return [null, null]
}

const userLoggedInCookie = () => {
    return Cookies.get('ipui')
}

function appendIpuiToEventName(eventName) {
    let ipui = Cookies.get('ipui')
    if (ipui) {
        eventName += `:ipui=${ipui.replace(/&1$/, '')}`
    }
    return eventName
}

function appendIpuiToURL(url) {
    let currentUrl = new URL(url)
    let ipui = Cookies.get('ipui')

    let prefix = ''

    if (currentUrl.hash && currentUrl.hash[currentUrl.hash.length - 1] !== '&') {
        prefix = '&'
    }

    if (ipui) {
        currentUrl.hash += `${prefix}ipui=${ipui.replace(/&1$/, '')}`
    }
    return currentUrl.href
}

function fathomTrackEvent(eventName) {
    Fathom.trackEvent(appendIpuiToEventName(eventName))
}

function googleTrackevent(eventName) {
    if (window.gtag) {
        window.gtag('event', eventName, {
            'page_location': appendIpuiToURL(window.location.href),
        })
    }
}

function trackEvent(eventName) {
    fathomTrackEvent(eventName)
    googleTrackevent(eventName)
}

function fathomInit() {
    const fathomIdElement = document.getElementById('FATHOM_ID')
    const fathomId = fathomIdElement ? JSON.parse(fathomIdElement.innerText) : null
    if (!fathomId) {
        return
    }
    const observer = new MutationObserver(mutationsList => {
        for (let mutation of mutationsList) {
            if (!(mutation.type === 'childList' && mutation.addedNodes.length) || mutation.addedNodes[0].id !== 'fathom-script') {
                return
            }

            mutation.addedNodes[0].onload = function () {
                window.fathom.trackPageview = function () {
                    function qs() { // copy from fathom as inaccessible any other way
                        for (var pair, data = {}, pairs = window.location.search.substring(window.location.search.indexOf("?") + 1).split("&"), i = 0; i < pairs.length; i++)
                            pairs[i] && (pair = pairs[i].split("="),
                            -1 < ["keyword", "q", "ref", "s", "utm_campaign", "utm_content", "utm_medium", "utm_source", "utm_term", "action", "name", "pagename", "tab", "via"].indexOf(decodeURIComponent(pair[0]))) && (data[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]));
                        return data
                    }

                    let location = window.location
                    let origin = location.origin
                    let pathnameToSend = appendIpuiToURL(window.location.href)
                    let referrer = document.referrer.indexOf(origin) < 0 ? document.referrer : ""
                    this.send({
                        h: origin,
                        p: pathnameToSend.replace(origin, ''),
                        r: referrer,
                        sid: fathomId,
                        qs: JSON.stringify(qs())
                    });
                }
                Fathom.trackPageview()
            }
            observer.disconnect();
        }
    })

    observer.observe(document.querySelector('head'), {childList: true})
    Fathom.load(fathomId, {auto: false, spa: 'hash'})
}

const shouldPopupLogin = () => {
    const [uahKey, uahExpiryKey] = getUahAndExpiryKeys()

    if (!uahKey || !uahExpiryKey) return false

    if (userLoggedInCookie()) {
        return false
    }

    const storageAllowedHash = localStorage.getItem(uahKey)



    // ignore the &mode=* part of the hash, can be the last part of the hash
    // or in the middle, checking old hashes for backwards-compatibility
    const routeHash = window.location.hash

    // this prevents a flash of the login popup
    if (!routeHash) return false

    const hashExpiryFromNow = (new Date().getTime() + 86400000).toString()

    const currentEncodedHash = btoa(routeHash)

    if (!storageAllowedHash) {
        localStorage.setItem(uahKey, currentEncodedHash)
        localStorage.setItem(uahExpiryKey, hashExpiryFromNow)
        return false
    }

    let storageAllowedHashExpiry = localStorage.getItem(uahExpiryKey)

    if (!storageAllowedHashExpiry) {
        localStorage.setItem(uahExpiryKey, hashExpiryFromNow)
        storageAllowedHashExpiry = hashExpiryFromNow
    }

    if (storageAllowedHash === currentEncodedHash) {
        localStorage.setItem(uahExpiryKey, hashExpiryFromNow)
        return false
    }

    if (new Date().getTime() > parseInt(storageAllowedHashExpiry)) {
        localStorage.setItem(uahKey, currentEncodedHash)
        localStorage.setItem(uahExpiryKey, hashExpiryFromNow)
        return false
    }

    return true
}

let Optionplan = {
    building: true,

    /**
     * Class initialiser (on load).
     */
    init: function() {
        Bugsnag.start(bugsnagConfig)
        Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
        fathomInit()
        // Vue pre-initialise logic.
        let default_mode = Optionplan.get_mode_from_url()

        // Clone the defaults, so they don't get changed when the inputs get changed.
        Optionplan.inputs = {}
        $.extend(true, Optionplan.inputs, OptionplanConfig.input_defaults[default_mode])
        Optionplan.input_defaults = {}
        $.extend(true, Optionplan.input_defaults, OptionplanConfig.input_defaults[default_mode])
        Optionplan.input_options = {}
        $.extend(true, Optionplan.input_options, OptionplanConfig.input_options)

        this.app = new Vue({
            el: '#OptionplanApp',
            delimiters: ['[[', ']]'],
            data: {
                mode: default_mode,
                inputs: Optionplan.inputs,
                input_options: Optionplan.input_options,
                input_defaults: Optionplan.input_defaults,
                inputs_tracked: {},
                initial_inputs_changed: false,
                theme_path: OptionplanConfig.theme_path,
                sliders: {
                    $salary_allocation_level: $([]),
                    $equity_allocation_level: $([]),
                },
                charts: {},
                active_text_area: 'initial_amount_raised',
                sectors: OptionplanConfig.sectors,
                roles: OptionplanConfig.roles,
                is_large_screen: document.documentElement.clientWidth > 768 && !OptionplanConfig.embedded,
                listening_url: true,
                export_inputs: window.location.hash ? btoa(window.location.hash) : '==',
                export_url: window.location.hash && window.location.hash != '#_' ? window.location.href : window.location.origin + window.location.pathname,
                export_url_visible: false,
                export_url_sidebar_visible: false,
                assumptions_visible: false,
                assumptions_part2_visible: false,
                embed: {
                    active: OptionplanConfig.embedded,
                    step: 1,
                    role: null,
                    role_level: null,
                },
                modals: {
                    hiring_plans_executive_modal_active: false,
                    hiring_plans_employee_modal_active: false,
                    mobile_analysis_active: false,
                    assumptions_active: false,
                    equity_allocation_level_modal_active: false,
                    salaries_employee_modal_active: false,
                    hiring_plans_employee_seed_modal_active: false,
                },
                show_more_countries: false,
                modal_open: false,
                timers: {},
                aggression_overwritten: false,
                aggression_changed: false,
                progress_bar_percent: 0,
                diagonal: {
                    angle: 13 * Math.PI / 180,
                    sin: Math.sin(13 * Math.PI / 180),
                },
                show_login_popup: false,
            },

            components: {
                ivShare,
                ivSimpleChart,
                ivBarChart,
                VueSlider,
                VueRadio,
                Money,
            },

            created: function() {
                let default_mode = Optionplan.get_mode_from_url()

                // We have to call parse_url from within change_mode to make sure the correct defaults AND url params get loaded.
                this.change_mode(default_mode, true, true)

                $(document).on('onIvUserLocation', this.user_location_change);

                window.addEventListener('resize', throttle(this.onResizeThrottle, 100))
                window.addEventListener('resize', debounce(this.onResizeDebounce, 700))
                this.onResizeDebounce()

                window.addEventListener('scroll', throttle(this.onScroll, 250))
                this.onScroll()

                // Tooltips on inputs
                this.$nextTick(function() {
                    tippy('[data-tippy-content]', {})
                })

                let that = this
                this.$nextTick(function() {
                    attachSocialShareEvents()

                    this.set_percentage_input_suffix_position()

                    // Delay some actions (needed for old IE).
                    setTimeout(function() {
                        that.activate_flag()
                        that.set_sidebar_width()
                    }, 10)

                    this.$nextTick(function() {
                        $('html, body').scrollTop(0)

                        // Sticky sidebar.
                        stickybits('.sidebar', { stickyBitStickyOffset: 100 })
                    })
                })

                setTimeout(function() {
                    Optionplan.building = false
                }, 100)
            },

            computed: {
                seed: function() {
                    return this.mode === 'seed'
                },

                venture: function() {
                    return this.mode === 'venture'
                },

                questions: function() {
                    return OptionplanConfig.questions[this.mode]
                },

                role_order: function() {
                    return OptionplanConfig.role_order[this.mode]
                },

                text: function() {
                    return OptionplanConfig.text[this.mode]
                },

                /**
                 * Get the total number of executives in the default settings.
                 */
                total_hiring_plan_executives_default: function() {
                    if (this.mode == 'seed') {
                        return []
                    }
                    return {
                        cxo: this.validate_number(this.input_defaults['hires_cxo_individual']),
                        vp: this.validate_number(this.input_defaults['hires_vp_individual']),
                        total: this.validate_number(this.input_defaults['hires_cxo_individual']) + this.validate_number(this.input_defaults['hires_vp_individual']),
                    }
                },

                /**
                 * Get the total number of executives the user has given.
                 *
                 * Represents E10, E11 and SUM(E10,E1).
                 */
                total_hiring_plan_executives: function() {
                    if (this.mode == 'seed') {
                        return []
                    }
                    return {
                        cxo: this.validate_number(this.inputs['hires_cxo_individual']),
                        vp: this.validate_number(this.inputs['hires_vp_individual']),
                        total: this.validate_number(this.inputs['hires_cxo_individual']) + this.validate_number(this.inputs['hires_vp_individual']),
                    }
                },

                /**
                 * Get the total number of employees in the default settings.
                 */
                total_hiring_plan_employees_default: function() {
                    let totals = {
                        total: 0,
                        roles: {},
                        sectors: {},
                    }

                    for (let sector in this.inputs.hiring_plans.employee) {
                        totals.sectors[sector] = 0
                        for (let role in this.inputs.hiring_plans.employee[sector].counts) {
                            let count = this.validate_number(this.input_defaults['hires_' + sector + '_' + role])
                            if (typeof totals.roles[role] == 'undefined') {
                                totals.roles[role] = 0
                            }
                            totals.total = totals.total + count
                            totals.sectors[sector] = totals.sectors[sector] + count
                            totals.roles[role] = totals.roles[role] + count
                        }
                    }

                    return totals
                },

                /**
                 * Get the total number of employees the user has given.
                 *
                 * Represents SUM(E5, E6, E7, F5, F6, F7, G5, G6, G7), SUM(E5, E6, E7), SUM(F5, F6, F7) and SUM(G5, G6, G7).
                 */
                total_hiring_plan_employees: function() {
                    let totals = {
                        total: 0,
                        roles: {},
                        sectors: {},
                    }

                    for (let sector in this.inputs.hiring_plans.employee) {
                        totals.sectors[sector] = 0
                        for (let role in this.inputs.hiring_plans.employee[sector].counts) {
                            let count = this.validate_number(this.inputs['hires_' + sector + '_' + role])
                            if (typeof totals.roles[role] == 'undefined') {
                                totals.roles[role] = 0
                            }
                            totals.total = totals.total + count
                            totals.sectors[sector] = totals.sectors[sector] + count
                            totals.roles[role] = totals.roles[role] + count
                        }
                    }

                    return totals
                },

                /**
                 * Get the total ESOP data for all aggression levels.
                 */
                total_esop: function() {
                    let totals = []

                    // Loop through the equity allocations and calculate the total for the user input.
                    for (let i in this.input_options.equity_allocations) {
                        let equity = this.input_options.equity_allocations[i]
                        if (typeof this.input_options.equity_allocations[i][this.mode] != 'undefined') {
                            equity = equity[this.mode]
                        }

                        let upside_totals = this.calculate_upside_totals(equity)

                        let total_value = this.make_percentage(upside_totals.pre_exit.total / this.seed_valuation_post_funding)
                        if (this.venture) {
                            total_value = this.pre_a_diluted_equity_post_a + this.make_percentage(this.post_a_salaries_total(equity) / this.series_a_valuation_post_funding) + this.make_percentage(this.inputs.esop_contingency)
                        }

                        totals.push({
                            'id': this.input_options.equity_allocations[i].id,
                            'label': this.input_options.equity_allocations[i].label,
                            'value': total_value,
                        })
                    }

                    // Order the totals based on value.
                    totals.sort(function(a, b) {
                        return a.value - b.value
                    })

                    return totals
                },

                /**
                 * Get the total ESOP data for the user's selected equity allocation.
                 */
                total_esop_you: function() {
                    let total_esop = this.total_esop
                    for (let i = 0; i < total_esop.length; i++) {
                        if (total_esop[i].id == this.equity_allocation.id) {
                            return total_esop[i]
                        }
                    }
                },

                /**
                 * Return the "you" ESOP total with 2 neighbours for comparison.
                 */
                total_esop_you_with_2: function() {
                    let totals = this.total_esop
                    let total_you = $.extend({}, this.total_esop_you)
                    let you_key = null
                    let before = []
                    let after = []
                    let comparison = { data: [], before: null, after: null }

                    // Build arrays of lower values and higher values.
                    for (let i = 0; i < totals.length; i++) {
                        if (totals[i].id == total_you.id) {
                            you_key = i
                            if (before.length) {
                                comparison.before = before.slice(-1).pop()
                            }
                        }

                        if (you_key === null) {
                            before.push(totals[i])
                        } else if (you_key !== i) {
                            after.push(totals[i])
                        }
                    }

                    if (after.length) {
                        comparison.after = after.slice(0, 1).pop()
                    }

                    // Ideally we want "you" to be value 2.
                    if (after.length) {
                        comparison.data = before.slice(-1)
                    } else {
                        comparison.data = before.slice(-2)
                    }
                    total_you.label = 'Your ' + (this.seed ? 'allocated' : 'total') + ' options'
                    comparison.data.push(total_you)
                    Array.prototype.push.apply(comparison.data, after.slice(0, 3 - comparison.data.length))

                    return comparison
                },

                /**
                 * Get the upside per person.
                 *
                 * Represents T64, T65, Y59, Y60, Y61, Z59, Z60, Z61, AA59, AA60 and AA61 on level 5.
                 */
                upside_per_person: function() {
                    return this.calculate_upside_per_person(this.equity_allocation)
                },

                /**
                 * Get the totals for upside calculations.
                 *
                 * Represents SUM(T64, T65), T64, T65, SUM(T59, T60, T61), T59, T60, T61, SUM(U59, U60, U61), U59, U60, U61, SUM(V59, V60, V61), V59, V60 and V61 for level 5.
                 */
                upside_totals: function() {
                    return this.calculate_upside_totals(this.equity_allocation)
                },

                /**
                 * Get the average upside for executives and non executives.
                 *
                 * Represents T68 and T69 for level 5.
                 */
                upside_average: function() {
                    return {
                        employee: this.upside_totals.at_exit.levels.employee / this.total_hiring_plan_employees.total,
                        executive: this.upside_totals.at_exit.levels.executive / this.total_hiring_plan_executives.total,
                    }
                },

                /**
                 * Get upsides for roles in descending order.
                 *
                 * Represents Y64, Y65, AA64, AA65, AA66 for level 5.
                 */
                upside_by_role: function() {
                    let totals = []

                    for (let role in this.upside_totals.at_exit.roles) {
                        let count = role == 'cxo' || role == 'vp' ? this.total_hiring_plan_executives[role] : this.total_hiring_plan_employees.roles[role]
                        totals.push({
                            role: role,
                            value: this.upside_totals.at_exit.roles[role] / count,
                        })
                    }

                    // Sort the upsides in descending order.
                    totals.sort(function(a, b) {
                        return b.value > a.value
                    })

                    return totals
                },

                /**
                 * Get the data for the user's selected country.
                 */
                selected_country: function() {
                    for (let x = 0; x < this.input_options.countries.length; x++) {
                        if (this.input_options.countries[x].id == this.inputs.employee_country) {
                            this.activate_flag()
                            return this.input_options.countries[x]
                        }
                    }
                },

                /**
                 * Get the funding round for the user's selected dilution level.
                 */
                selected_dilution: function() {
                    for (let level in this.input_options.funding_rounds[this.mode]) {
                        if (this.input_options.funding_rounds[this.mode][level].id == this.inputs.expected_funding_rounds_pre_exit) {
                            return this.input_options.funding_rounds[this.mode][level]
                        }
                    }
                },

                /**
                 * Get the equity allocation data for the user's selected aggression level.
                 */
                equity_allocation: function() {
                    let selected_allocation = this.input_options.equity_allocations[this.inputs.equity_allocation_level];

                    if (typeof selected_allocation[this.mode] != 'undefined') {
                        selected_allocation = selected_allocation[this.mode]
                    }
                    this.aggression_changed = true

                    return selected_allocation
                },

                /**
                 * Get the seed valuation (post funding).
                 */
                seed_valuation_post_funding: function() {
                    return this.inputs.initial_amount_raised + this.inputs.pre_funding_valuation
                },

                /**
                 * Get the series A valuation (post funding).
                 */
                series_a_valuation_post_funding: function() {
                    return this.inputs.initial_amount_raised + this.inputs.pre_funding_valuation
                },

                /**
                 * Calculate the post-A equity.
                 *
                 * Represents: B64 on Level 5.
                 */
                post_a_hires_equity: function() {
                    // =O67/B6
                    return this.post_a_salaries_total(this.equity_allocation) / this.series_a_valuation_post_funding
                },

                /**
                 * Calculate the pre-A diluted equity post-A.
                 *
                 * Represents: B9.
                 */
                pre_a_diluted_equity_post_a: function() {
                    // =(B6-B4)/B6*B8
                    return this.inputs.pre_funding_valuation / this.series_a_valuation_post_funding * (this.inputs.initial_equity_allocated * 100)
                },

                /**
                 * Get the assumed dilution amount between series A and exit.
                 */
                assumed_dilution_between_a_and_exit: function() {
                    return 1 - this.series_c_dilution_vs_series_a
                },

                /**
                 * Valuation is less than total fund raising.
                 */
                is_valuation_less_than_total_fundraising: function() {
                    // =IF(B16<((B5/1000000)+B20+B25),"Problem","OK").
                    let max_valuation = 0
                    // Starts with 1 as we skip round A.
                    for (let i = 0; i < this.input_options.funding_rounds[this.mode].length; i++) {
                        max_valuation = max_valuation + this.input_options.funding_rounds[this.mode][i].capital_raised
                        if (this.input_options.funding_rounds[this.mode][i].id == this.selected_dilution.id) {
                            break
                        }
                    }

                    // On Venture, seed is 4m but this is not shown.
                    var seed_amount = this.mode == 'venture' ? 4000000 : 0;
                    return this.inputs.estimated_exit_valuation < max_valuation + this.inputs.initial_amount_raised + seed_amount
                },

                /**
                 * Valuation is less than series A post funding.
                 */
                is_valuation_less_than_initial_funding: function() {
                    return this.inputs.estimated_exit_valuation < this.series_a_valuation_post_funding
                },

                /**
                 * Valuation is over ambitious based on funding.
                 *
                 * Seed only funding, but exit valuation >= $100m
                 * OR Series A funding, but exit valuation >= $300m
                 * OR Series A + B funding, but exit valuation >= $1bn
                 * OR Series A + B + C funding, but exit valuation >= $3bn
                 *
                 * Series A + B + C + D funding has no limit.
                 */
                is_valuation_over_ambitious: function() {
                    return (this.selected_dilution.id == 'seed-only' && this.inputs.estimated_exit_valuation > 100000000)
                        || (this.selected_dilution.id == 'series-a' && this.inputs.estimated_exit_valuation > 300000000)
                        || (this.selected_dilution.id == 'series-b' && this.inputs.estimated_exit_valuation > 1000000000)
                        || (this.selected_dilution.id == 'series-b-and-c' && this.inputs.estimated_exit_valuation > 3000000000)
                },

                /**
                 * Pull together the data for the "Total option pool required" chart.
                 */
                chart_data_option_pool_required: function() {
                    let bar1 = this.round(this.pre_a_diluted_equity_post_a, 1)
                    let bar2 = bar1 + this.make_percentage(this.post_a_hires_equity)
                    let bar3 = bar2 + this.make_percentage(this.inputs.esop_contingency)

                    let chart_data = {
                        labels: [],
                        columns: [],
                        max: 0,
                    }

                    chart_data.labels = [
                        'Options for Pre-A hires',
                        'Options for Post-A hires',
                        this.is_large_screen ? 'Contingency buffer' : 'Buffer',
                        'Total options',
                    ]

                    chart_data.columns = [
                        [[0, bar1]],
                        [[bar1, bar2]],
                        [[bar2, bar3]],
                        [[0, bar1], [bar1, bar2], [bar2, bar3]],
                    ]
                    let max = bar3
                    chart_data.max = this.chart_axis_y_max_number(max)

                    return chart_data
                },

                /**
                 * Pull together the data for the "Total option pool comparison" chart.
                 */
                chart_data_option_pool_comparison: function() {
                    let chart_data = { labels: [], columns: [], max: 0 }
                    for (let i = 0; i < this.total_esop_you_with_2.data.length; i++) {
                        chart_data.labels.push(this.total_esop_you_with_2.data[i].label)

                        // "You" data has altered label
                        if (this.total_esop_you_with_2.data[i].id == this.equity_allocation.id) {
                            this.total_esop_you_with_2.data[i].label = 'Your ' + (this.seed ? 'allocated' : 'total') + ' options'
                        }
                        chart_data.columns.push(this.round(this.total_esop_you_with_2.data[i].value, 1))
                    }

                    let max = Math.max.apply(Math, chart_data.columns)
                    chart_data.max = this.chart_axis_y_max_number(max)
                    return chart_data
                },

                /**
                 * Pull together the data for the "Employee upside summary, pre-tax".
                 */
                chart_data_employee_upside_summary: function() {
                    let chart_data = { labels: [], columns: [], max: 0 }

                    for (let i = 0; i < this.upside_by_role.length; i++) {
                        let role = this.upside_by_role[i].role
                        let value = this.upside_by_role[i].value
                        if (value) {
                            chart_data.labels.push(this.roles[role].label)
                            chart_data.columns.push(this.force_positive(value))
                        }
                    }

                    // Set Y axis max values.
                    let max = Math.max.apply(Math, chart_data.columns)
                    chart_data.max = this.chart_axis_y_max_number(max)

                    return chart_data
                },

                /**
                 * Pull together the data for the "Tech employee upside summary, pre-tax".
                 */
                chart_data_employee_upside_summary_tech: function() {
                    let chart_data = { labels: [], columns: [], max: 0 }
                    let upside_per_person_at_exit = this.upside_per_person.at_exit.employee
                    let roles = this.role_order.executive.concat(this.role_order.employee);

                    for (let i = 0; i < roles.length; i++) {
                        if (typeof upside_per_person_at_exit['tech'][roles[i]] != 'undefined') {
                            chart_data.labels.push(this.sectors['tech'].label + ' ' + this.roles[roles[i]].label)
                            chart_data.columns.push(this.force_positive(upside_per_person_at_exit['tech'][roles[i]]))
                        }
                    }

                    // Set Y axis max values based on this and the tech chart.
                    let tech = Object.keys(upside_per_person_at_exit['tech']).map(function(key) {
                        return upside_per_person_at_exit['tech'][key]
                    })
                    let nonTech = Object.keys(upside_per_person_at_exit['non-tech']).map(function(key) {
                        return upside_per_person_at_exit['non-tech'][key]
                    })
                    let max = Math.max.apply(Math, tech.concat(nonTech))

                    chart_data.max = this.chart_axis_y_max_number(max)

                    return chart_data
                },

                /**
                 * Pull together the data for the "Non-tech employee upside summary, pre-tax".
                 *
                 * @todo - merge with employee_upside_summary_tech();
                 */
                chart_data_employee_upside_summary_non_tech: function() {
                    // Changed data format slightly
                    let chart_data = { labels: [], columns: [], max: 0 }
                    let upside_per_person_at_exit = this.upside_per_person.at_exit.employee
                    let roles = this.role_order.executive.concat(this.role_order.employee)

                    for (let i = 0; i < roles.length; i++) {
                        if (typeof upside_per_person_at_exit['non-tech'][roles[i]] != 'undefined') {
                            chart_data.labels.push(this.sectors['non-tech'].label + ' ' + this.roles[roles[i]].label)
                            chart_data.columns.push(this.force_positive(upside_per_person_at_exit['non-tech'][roles[i]]))
                        }
                    }

                    // Set Y axis max values based on this and the tech chart.
                    let tech = Object.keys(upside_per_person_at_exit['tech']).map(function(key) {
                        return upside_per_person_at_exit['tech'][key]
                    })
                    let nonTech = Object.keys(upside_per_person_at_exit['non-tech']).map(function(key) {
                        return upside_per_person_at_exit['non-tech'][key]
                    })
                    let max = Math.max.apply(Math, tech.concat(nonTech))
                    chart_data.max = this.chart_axis_y_max_number(max)

                    return chart_data
                },

                /**
                 * Pull together the data for the "Executive upside: pre and post-tax".
                 *
                 * This is used directly by the iv-simple-charts component.
                 */
                chart_data_executive_upside_by_role: function() {
                    let chart = { data: [], max: 0 }
                    let raw_values = []
                    for (let role in this.upside_per_person.at_exit.executive) {
                        let value = this.force_positive(this.upside_per_person.at_exit.executive[role])
                        chart.data.push({
                            role: role,
                            dataset: [['a', value], ['b', this.deduct_tax(value, role)]],
                        })
                        raw_values.push(value)
                    }

                    // Order roles by value.
                    chart.data.sort(function(a, b) {
                        if (a.dataset[0][1] > b.dataset[0][1]) {
                            return -1
                        }
                        if (a.dataset[0][1] < b.dataset[0][1]) {
                            return 1
                        }
                        return 0
                    })

                    chart.max = Math.max.apply(Math, raw_values)
                    return chart
                },

                /**
                 * Pull together the data for the "Employee upside: pre and post-tax" chart.
                 *
                 * This is used directly by the iv-simple-charts component.
                 */
                chart_data_employee_upside_by_type_role: function() {
                    let chart = { types: {}, max: 0 }
                    let raw_values = []
                    for (let type in this.upside_per_person.at_exit.employee) {
                        chart.types[type] = []
                        for (let role in this.upside_per_person.at_exit.employee[type]) {
                            let value = this.force_positive(this.upside_per_person.at_exit.employee[type][role])
                            chart.types[type].push({
                                role: role,
                                dataset: [['a', value], ['b', this.deduct_tax(value, role)]],
                            })
                            raw_values.push(value)
                        }

                        // Order roles by value.
                        chart.types[type].sort(function(a, b) {
                            if (a.dataset[0][1] > b.dataset[0][1]) {
                                return -1
                            }
                            if (a.dataset[0][1] < b.dataset[0][1]) {
                                return 1
                            }
                            return 0
                        })
                    }

                    chart.max = Math.max.apply(Math, raw_values)
                    return chart
                },

                /**
                 * We need to clone the inputs object so Vue can watch all the sub properties.
                 */
                all_inputs: function() {
                    return $.extend(true, {}, this.inputs)
                },

                /**
                 * We need to clone the modal object so Vue can watches all the sub properties.
                 */
                all_modals: function() {
                    return $.extend(true, {}, this.modals)
                },
            },

            watch: {
                /**
                 * Watch all the inputs.
                 */
                all_inputs: async function() {
                    /**
                    * Check the inputs have changed.
                    * If they have, update the URL.
                    * @returns {Promise<boolean>}
                    */
                    this.set_percentage_input_suffix_position()

                    if (this.listening_url && !this.embed.active) {
                        this.update_url()
                    }

                    // uncomment to enable login screen for option plan
                    // debounce(() => {
                    //     this.show_login_popup = shouldPopupLogin()
                    //     if (this.show_login_popup) {
                    //         trackEvent('optionplan:login:opened')
                    //         document.body.style.overflowY = 'hidden'
                    //         return
                    //     }
                    //     document.body.style.overflowY = 'auto'
                    // }, 100)()
                },

                // Watch the modals object to react when one is opened or closed.
                all_modals: function(modals) {
                    for (let i in modals) {
                        if (modals[i]) {
                            let that = this
                            let scroll_top = window.pageYOffset

                            this.$nextTick(function() {
                                $('html').addClass('noscroll').css('top', -scroll_top)
                            })

                            this.modal_open = true
                            return
                        }
                    }

                    let scroll_top = parseInt($('html').css('top'))
                    $('html').removeClass('noscroll')
                    $('html,body').scrollTop(-scroll_top)
                    this.modal_open = false
                },
            },

            methods: {
                init_sliders: function() {
                    // Sliders.
                    // Series A valuation slider.
                    this.sliders.pre_funding_valuation = {
                        // Note that :max="questions.q1_max" needs to be set on the component so the mode switching works.
                        max: this.questions.q1_max,
                        interval: 1000000,
                        duration: .15,
                        lazy: true,
                        tooltip: 'always',
                        tooltipFormatter: this.format_dollar,
                        marks: (value) => {
                            let max = this.questions.q1_max
                            let eu = this.questions.q1_eu
                            let us = this.questions.q1_us

                            if (value === 0) {
                                return { label: '$0' }
                            } else if (value === eu) {
                                return { label: `Europe\n${this.format_dollar(value)}` }
                            } else if (value === us) {
                                return { label: `US\n${this.format_dollar(value)}` }
                            } else if (value === max) {
                                return {
                                    label: this.format_dollar(value),
                                }
                            }
                            return false
                        },
                    }

                    // Series A funding goal slider.
                    //var that = this
                    this.sliders.initial_amount_raised = {
                        // Note that :max="questions.q2_max" needs to be set on the component so the mode switching works.
                        max: this.questions.q2_max,
                        duration: .15,
                        interval: 500000,
                        lazy: true,
                        tooltip: 'always',
                        tooltipFormatter: this.format_dollar,
                        marks: (value) => {
                            let max = this.questions.q2_max
                            let eu = this.questions.q2_eu
                            let us = this.questions.q2_us

                            if (value === 0) {
                                return { label: '$0' }
                            } else if (value === eu) {
                                return { label: `Europe\n${this.format_dollar(value)}` }
                            } else if (value === us) {
                                return { label: `US\n${this.format_dollar(value)}` }
                            } else if (value === max) {
                                return { label: this.format_dollar(value) }
                            }
                            return false
                        },
                    }

                    // Equity allocated to pre-Series A employees slider.
                    this.sliders.initial_equity_allocated = {
                        max: 0.15,
                        duration: .15,
                        interval: 0.005,
                        lazy: true,
                        tooltip: 'always',
                        tooltipFormatter: (value) => `${this.make_percentage(value)}%`,
                        marks: (value) => {
                            if (value === 0 || value === this.input_defaults.initial_equity_allocated || value === this.sliders.initial_equity_allocated.max) {
                                return { label: `${this.make_percentage(value)}%` }
                            }
                            return false
                        },

                    }

                    // Equity allocated to pre-Series A employees slider.
                    this.sliders.estimated_exit_valuation = {
                        data: this.is_large_screen ? this.input_options.slider_ticks.estimated_exit_valuation.full : this.input_options.slider_ticks.estimated_exit_valuation.reduced,
                        lazy: true,
                        duration: .15,
                        tooltip: 'always',
                        tooltipFormatter: (value) => {
                            let label = this.format_dollar(value)
                            if (label == '$20bn') {
                                label = label + '+'
                            }
                            return label
                        },
                        marks: (value) => {
                            let allowed = [0, 250000000, 500000000, 1000000000, 5000000000, 10000000000, 20000000000]

                            if (!this.is_large_screen) {
                                allowed = [0, 250000000, 500000000, 1000000000, 5000000000, 20000000000]
                            }

                            if (allowed.includes(value)) {
                                if (value < 20000000000) {
                                    return { label: this.format_dollar(value) }
                                } else {
                                    return { label: `${this.format_dollar(value)}+` }
                                }
                            }
                            return false
                        },
                    }

                    // Option allocation level slider.
                    this.sliders.salary_allocation_level = {
                        data: Object.keys(this.input_options.equity_allocations).map(Number),
                        adsorb: true,
                        lazy: false,
                        duration: .15,
                        tooltip: 'none',
                        marks: (value) => {
                            let text_label = this.input_options.equity_allocations[value].label
                            let html_label = text_label.replace(/^(\w+)/, '<strong>$1</strong>')
                            return {
                                label: html_label,
                            }
                        },
                    }

                    // Technical DNA level slider.
                    this.sliders.equity_allocation_level = {
                        data: Object.keys(this.input_options.equity_allocation_level[this.mode]).map(Number),
                        adsorb: true,
                        duration: .15,
                        lazy: false,
                        //realTime: true,
                        tooltip: 'none',
                        marks: (value) => {
                            if (value === 1) {
                                return {
                                    // NB: Exclude the wrapper span because it's in optionplan.html
                                    label: '<strong>Very low </strong><span>e.g. </span><span>content </span>',
                                }
                            } else if (value === 6) {
                                return {
                                    // NB: Exclude the wrapper span because it's in optionplan.html
                                    label: '<strong>Very high </strong><span>e.g. </span><span>deep </span><span>learning </span>',
                                }
                            } else {
                                return { label: '<strong> </strong>' }
                            }
                        },
                    }

                    this.$nextTick(function() {
                        // @todo this shouldn't be needed
                        this.sliders.$equity_allocation_level = $('.slider__equity_allocation_level')
                        this.sliders.$salary_allocation_level = $('.slider__salary_allocation_level')
                        // No "drawn" callback on the slider :(
                        this.$nextTick(function() {
                            let that = this
                            setTimeout(function() {
                                that.recalculate_triangular_slider(that.sliders.$salary_allocation_level, that.inputs.salary_allocation_level, '.slider__salary_allocation_level')
                                that.recalculate_triangular_slider(that.sliders.$equity_allocation_level, that.inputs.equity_allocation_level, '.slider__equity_allocation_level')
                            }, 100)
                        })
                    })

                    // Hiring Plan sliders.
                    this.sliders.hiring_plans = {
                        executive: {},
                        employee: {},
                    }
                    for (let level in this.input_defaults.hiring_plans) {
                        if (level == 'executive') {
                            for (let role in this.input_defaults.hiring_plans[level]) {
                                this.sliders.hiring_plans.executive[role] = {
                                    count: {
                                        max: this.roles[role].limits.hires,
                                        adsorb: true,
                                        lazy: true,
                                        duration: .15,
                                        tooltip: 'always',
                                        marks: (value) => {
                                            let max = this.roles[role].limits.hires
                                            if (value === 0 || value === max) {
                                                return { label: value }
                                            }
                                            return false
                                        },
                                    },

                                    ownership: {
                                        max: this.roles[role].limits.ownership,
                                        interval: 1,
                                        adsorb: true,
                                        lazy: true,
                                        duration: .15,
                                        tooltip: 'always',
                                        tooltipFormatter: (value) => `${value}%`,
                                        marks: (value) => {
                                            let max = this.roles[role].limits.ownership
                                            if (value === 0 || value === max) {
                                                return { label: `${value}%` }
                                            }
                                            return false
                                        },
                                    },
                                }
                            }
                        } else {
                            for (let sector in this.input_defaults.hiring_plans[level]) {
                                this.sliders.hiring_plans.employee[sector] = {}
                                for (let role in this.input_defaults.hiring_plans[level][sector].counts) {
                                    this.sliders.hiring_plans.employee[sector][role] = {
                                        count: {
                                            max: this.roles[role].limits.hires,
                                            adsorb: true,
                                            lazy: true,
                                            duration: .15,
                                            tooltip: 'always',
                                            marks: (value) => {
                                                let max = this.roles[role].limits.hires
                                                if (value === 0 || value === max) {
                                                    return { label: value }
                                                }
                                                return false
                                            },
                                        },

                                        salary: {
                                            max: this.roles[role].limits.salary,
                                            interval: 10000,
                                            adsorb: true,
                                            lazy: true,
                                            duration: .15,
                                            tooltip: 'always',
                                            tooltipFormatter: (value) => this.format_dollar(value),
                                            marks: (value) => {
                                                let max = this.roles[role].limits.salary
                                                if (value === 0 || value === max) {
                                                    return { label: this.format_dollar(value) }
                                                }
                                                return false
                                            },
                                        },

                                        ownership: {
                                            max: this.roles[role].limits.ownership,
                                            interval: 1,
                                            adsorb: true,
                                            lazy: true,
                                            duration: .15,
                                            tooltip: 'always',
                                            tooltipFormatter: (value) => `${value}%`,
                                            marks: (value) => {
                                                let max = this.roles[role].limits.ownership
                                                if (value === 0 || value === max) {
                                                    return { label: `${value}%` }
                                                }
                                                return false
                                            },
                                        },
                                    }
                                }
                            }
                        }
                    }
                },

                onSliderChange: function(slider_name) {
                    let $slider = this.sliders['$' + slider_name]
                    let model = this.inputs[slider_name]
                    let selector = '.slider__' + slider_name

                    this.recalculate_triangular_slider($slider, model, selector)

                    if (slider_name === 'salary_allocation_level' && !this.building) {
                        if (this.venture) {
                            // Reset everything for Venture mode.
                            this.reset_computed_inputs(this.inputs.equity_allocation_level, this.inputs.salary_allocation_level)
                        } else {
                            // Reset everything apart from equity for Seed mode
                            this.reset_computed_inputs(this.inputs.equity_allocation_level, this.inputs.salary_allocation_level, ['total_hiring_plan_executives', 'salary'])
                        }
                    }

                    if (slider_name === 'equity_allocation_level' && !this.building) {
                        this.reset_computed_inputs(this.inputs.equity_allocation_level, this.inputs.salary_allocation_level, ['equity'])
                    }
                },

                /**
                 * Calculate the total ($) of post-A salaries adjusted for equity % for the given aggression level.
                 */
                post_a_salaries_total: function(equity_allocation) {
                    let total_salaries = 0
                    let ownership = 0
                    let salary = 0

                    for (let level in this.inputs.hiring_plans) {
                        if (level == 'executive') {
                            for (let role in this.inputs.hiring_plans[level]) {
                                ownership = equity_allocation[level][role].individual
                                if (this.equity_allocation.id == equity_allocation.id) {
                                    ownership = this.inputs['equity_' + role + '_individual'] / 100
                                }
                                // Executive equity ($) - equity % * salary * Count.
                                total_salaries = total_salaries + ownership * this.series_a_valuation_post_funding * this.inputs['hires_' + role + '_individual']
                            }
                        } else {
                            for (let sector in this.inputs.hiring_plans[level]) {
                                for (let role in this.inputs.hiring_plans[level][sector].counts) {
                                    ownership = equity_allocation[level][sector][role]
                                    salary = this.input_defaults.hiring_plans[level][sector].salaries[this.inputs.salary_allocation_level][role]
                                    if (this.equity_allocation.id == equity_allocation.id) {
                                        ownership = this.inputs['equity_' + sector + '_' + role] / 100
                                        salary = this.inputs['salary_' + sector + '_' + role]
                                    }
                                    // Non-Executive equity ($) - equity % * Salary * Count.
                                    total_salaries = total_salaries + ownership * salary * this.inputs['hires_' + sector + '_' + role]
                                }
                            }
                        }
                    }

                    return total_salaries
                },

                /**
                 * Calculate the upside totals for the given equity allocation.
                 */
                calculate_upside_totals: function(equity_allocation) {
                    let upside_per_person = this.calculate_upside_per_person(equity_allocation)
                    let upside = {
                        at_exit: {
                            total: 0,
                            levels: { executive: 0, employee: 0 },
                            roles: {
                                cxo: 0,
                                vp: 0,
                                director: 0,
                                lead: 0,
                                individual: 0,
                                junior: 0,
                                mid: 0,
                                senior: 0,
                            },
                            sectors: {
                                engineering_devops_ml: { director: 0, lead: 0, individual: 0 },
                                product_design_bizdev: { director: 0, lead: 0, individual: 0 },
                                marketing_sales_success_hr_finance: { director: 0, lead: 0, individual: 0 },
                                inside_sales_support: { director: 0, lead: 0, individual: 0 },
                                tech: { junior: 0, mid: 0, senior: 0 },
                                'non-tech': { junior: 0, mid: 0, senior: 0 },
                            },
                        },
                        pre_exit: {},
                    }
                    $.extend(true, upside.pre_exit, upside.at_exit)

                    for (let level in this.inputs.hiring_plans) {
                        if (level == 'executive') {
                            for (let role in this.inputs.hiring_plans[level]) {
                                let upside_amount_total_at_exit = upside_per_person.at_exit[level][role] > 0 ? upside_per_person.at_exit[level][role] * this.inputs['hires_' + role + '_individual'] : 0
                                upside.at_exit.levels.executive = upside.at_exit.levels.executive + upside_amount_total_at_exit
                                upside.at_exit.roles[role] = upside_per_person.at_exit[level][role] * this.inputs['hires_' + role + '_individual']
                                if (this.mode == 'venture') {
                                    upside.at_exit.total = upside.at_exit.total + upside_amount_total_at_exit
                                }
                            }
                        } else {
                            for (let sector in this.inputs.hiring_plans[level]) {
                                for (let role in this.inputs.hiring_plans[level][sector].counts) {
                                    // At exit.
                                    let upside_amount_per_person_at_exit = upside_per_person.at_exit[level][sector][role]
                                    let upside_amount_total_at_exit = upside_amount_per_person_at_exit > 0 ? upside_amount_per_person_at_exit * this.inputs['hires_' + sector + '_' + role] : 0
                                    upside.at_exit.levels.employee = upside.at_exit.levels.employee + upside_amount_total_at_exit
                                    upside.at_exit.roles[role] = upside.at_exit.roles[role] + upside_amount_total_at_exit
                                    upside.at_exit.sectors[sector][role] = upside.at_exit.sectors[sector][role] + upside_amount_total_at_exit
                                    if (typeof this.input_defaults.hiring_plans.employee[sector] != 'undefined') {
                                        upside.at_exit.total = upside.at_exit.total + upside_amount_total_at_exit
                                    }

                                    // Pre exit.
                                    let upside_amount_per_person = upside_per_person.pre_exit[level][sector][role]
                                    let upside_amount_total = upside_amount_per_person > 0 ? upside_amount_per_person * this.inputs['hires_' + sector + '_' + role] : 0
                                    upside.pre_exit.levels.employee = upside.pre_exit.levels.employee + upside_amount_total
                                    upside.pre_exit.roles[role] = upside.pre_exit.roles[role] + upside_amount_total
                                    upside.pre_exit.sectors[sector][role] = upside.pre_exit.sectors[sector][role] + upside_amount_total
                                    if (typeof this.input_defaults.hiring_plans.employee[sector] != 'undefined') {
                                        upside.pre_exit.total = upside.pre_exit.total + upside_amount_total
                                    }
                                }
                            }
                        }
                    }

                    return upside
                },

                /**
                 * Calculate the upside person by stage.
                 */
                calculate_upside_per_person: function(equity_allocation) {
                    let upside = {
                        pre_exit: { 'employee': {}, 'executive': {} },
                        at_exit: { 'employee': {}, 'executive': {} },
                    }
                    let ownership = 0
                    let salary = 0

                    for (let mode in OptionplanConfig.input_defaults) {
                        for (let level in OptionplanConfig.input_defaults[mode].hiring_plans) {
                            if (level == 'executive') {
                                for (let role in this.input_defaults.hiring_plans[level]) {
                                    ownership = equity_allocation[level][role].individual
                                    if (this.equity_allocation.id == equity_allocation.id) {
                                        ownership = this.inputs['equity_' + role + '_individual'] / 100
                                    }

                                    // =IF((E64*(1-B38)*$B$15*1000000)-(B43*J64)<0,0,(E64*(1-B38)*B16*1000000)-(B43*J64))
                                    upside.at_exit[level][role] = (ownership * this.selected_dilution.cum_dilution_after_a * this.inputs.estimated_exit_valuation) - (this.selected_country.discount * (ownership * this.series_a_valuation_post_funding))

                                    // =E74*J74
                                    upside.pre_exit[level][role] = ownership * this.series_a_valuation_post_funding
                                }
                            } else {
                                for (let sector in OptionplanConfig.input_defaults[mode].hiring_plans[level]) {
                                    upside.at_exit[level][sector] = {}
                                    upside.pre_exit[level][sector] = {}
                                    for (let role in OptionplanConfig.input_defaults[mode].hiring_plans[level][sector].counts) {
                                        if (typeof equity_allocation[level][sector] != 'undefined') {
                                            ownership = equity_allocation[level][sector][role]
                                            salary = this.input_defaults.hiring_plans[level][sector].salaries[this.inputs.salary_allocation_level][role]
                                            if (this.equity_allocation.id == equity_allocation.id) {
                                                ownership = this.inputs['equity_' + sector + '_' + role] / 100
                                                salary = this.inputs['salary_' + sector + '_' + role]
                                            }

                                            // =IF((J59/B6*(1-B38)*B16*1000000)-($B$43*J59)<0,0,(J59/$B$6*(1-$B$38)*$B$16*1000000)-($B$43*J59))
                                            let adjusted_salary = ownership * salary
                                            upside.at_exit[level][sector][role] = (adjusted_salary / this.series_a_valuation_post_funding * this.selected_dilution.cum_dilution_after_a * this.inputs.estimated_exit_valuation) - (this.selected_country.discount * adjusted_salary)

                                            // =E74*J74
                                            upside.pre_exit[level][sector][role] = adjusted_salary
                                        }
                                    }
                                }
                            }
                        }
                    }

                    return upside
                },

                /**
                 * Formats a number into a human readable dollar amount.
                 */
                format_dollar: function(value, abbreviate, excessive) {
                    abbreviate = typeof abbreviate == 'undefined' ? true : abbreviate
                    excessive = typeof excessive == 'undefined' ? false : excessive
                    let formatted = value

                    // Abbreviated values.
                    if (abbreviate) {
                        // Billions.
                        if (value >= 1000000000) {
                            formatted = this.round((value / 1000000000), 2) + 'bn'
                        }

                        // Millions.
                        else if (value >= 1000000) {
                            formatted = this.round((value / 1000000), 2) + 'm'
                        }

                        // Thousands.
                        else if (value >= 1000) {
                            formatted = this.round_nearest_x(value, 1000) + 'k'
                        }

                        // Less than a thousand.
                        else {
                            formatted = this.round(value)
                        }
                    }

                    // Not abbreviating so restrict to 2 decimal places and add commas.
                    else if (value > 0) {
                        value = this.round(parseFloat(value)) + ''
                        let x = value.split('.')
                        let x1 = x[0]
                        let x2 = x.length > 1 ? '.' + x[1] : ''
                        let rgx = /(\d+)(\d{3})/

                        while (rgx.test(x1)) {
                            x1 = x1.replace(rgx, '$1' + ',' + '$2')
                        }

                        formatted = x1 + x2
                    }

                    if (excessive && this.inputs.estimated_exit_valuation == 20000000000) {
                        formatted = formatted + '+'
                    }

                    return '$' + formatted
                },

                /**
                 * Formatted function for y axis dollar values.
                 */
                format_dollar_y_axis: function(value, max) {
                    let formatted = this.format_dollar(value)

                    if (max > 1000000) {
                        let regexp = /\.[0-9]m/gi
                        if (formatted.match(regexp) || formatted == '$500k') {
                            return
                        }
                    }

                    return formatted
                },

                /**
                 * Round the number to the given number of decimal places.
                 */
                round: function(number, precision) {
                    precision = typeof precision !== 'undefined' ? precision : 0
                    return Math.round(number * Math.pow(10, precision)) / Math.pow(10, precision)
                },

                /**
                 * Typecasts the input to a number.
                 */
                validate_number: function(number) {
                    return number = +number || 0
                },

                /**
                 * Ensures a number is positive or 0. If it's negative, 1 is returned.
                 */
                force_positive: function(number) {
                    // 0 values are represented as 1. This is a work around for the fact the C3JS chart library does not allow 0
                    // values, the column is simply not rendered. So we must give it a value of 1 and formatter it as 0 in the
                    // formatter.
                    return number > 0 ? number : 1
                },

                /**
                 * Takes a 0.x number and makes it into a percentage number. E.g. 0.054353 becomes 5.
                 */
                make_percentage: function(number, precision) {
                    precision = typeof precision == 'undefined' ? 1 : precision
                    return this.round(number * 100, precision)
                },

                /**
                 * Apply the tax rate of the currently selected country to the given number.
                 */
                deduct_tax: function(number, role) {
                    let rate = 0

                    switch (role) {
                        case 'vp':
                        case 'cxo':
                        case 'director':
                            rate = this.selected_country.tax_higher
                            break
                        default:
                            rate = this.selected_country.tax_lower
                    }

                    return number - (number * rate)
                },

                /**
                 * Set the active section.
                 */
                activate_text_area: function(section) {
                    this.active_text_area = section
                },

                /**
                 * Calculates the max number for chart y axis.
                 */
                chart_axis_y_max_percentage: function(highest_value, excess) {
                    let next_5 = this.ceil_nearest_x(highest_value, 5)
                    excess = typeof excess == 'undefined' ? 5 : excess

                    return next_5 - highest_value >= 1 ? next_5 : next_5 + excess
                },

                /**
                 * Calculates the max number for chart y axis. This is the nearest 1m above the number plus another 1m.
                 */
                chart_axis_y_max_number: function(highest_value) {
                    if (highest_value <= 1000) {
                        return highest_value
                    }
                    // If the highest value is less than 1m we add 100k.
                    if (highest_value < 1000000) {
                        return this.ceil_nearest_hundred_thousand(highest_value) + 100000
                    }
                    // Over 10m we add 10m.
                    else if (highest_value > 10000000) {
                        return this.ceil_nearest_million(highest_value) + 10000000
                    }
                    // Over 1m so we can make the make ceil + 1m.
                    else {
                        return this.ceil_nearest_million(highest_value) + 1000000
                    }
                },

                /**
                 * Round the given number to the nearest X.
                 */
                round_nearest_x: function(value, x) {
                    return Math.round(value / x)
                },

                /**
                 * Round the given number up to the nearest X.
                 */
                ceil_nearest_x: function(value, x) {
                    return x * Math.ceil(value / x)
                },

                /**
                 * Round the given number up to the nearest million.
                 */
                ceil_nearest_million: function(value) {
                    return this.ceil_nearest_x(value, 1000000)
                },

                /**
                 * Round the given number up to the nearest million.
                 */
                ceil_nearest_hundred_thousand: function(value) {
                    return this.ceil_nearest_x(value, 100000)
                },

                /**
                 * Window resize event handler.
                 */
                onResizeThrottle: function() {
                    // Hide the diagonal slider during resize as it goes weird.
                    this.sliders.$salary_allocation_level.css('opacity', 0)
                    this.sliders.$equity_allocation_level.css('opacity', 0)
                },

                onResizeDebounce: function() {
                    this.is_large_screen = document.documentElement.clientWidth > 768 && !this.embed.active
                    this.set_sidebar_width()
                    this.recalculate_triangular_slider(this.sliders.$salary_allocation_level, this.inputs.salary_allocation_level, '.slider__salary_allocation_level')
                    this.recalculate_triangular_slider(this.sliders.$equity_allocation_level, this.inputs.equity_allocation_level, '.slider__equity_allocation_level')
                },

                /**
                 * Set the sidebar width, needed for older IE when position fixed is required.
                 */
                set_sidebar_width: function() {
                    // Set sidebar width when using the position sticky polyfil.
                    $('.js-stickybit-parent .sidebar').each(function(index, element) {
                        let $el = $(element)
                        $el.width($el.parent().width())
                    })
                },

                /**
                 * Window scroll event handler.
                 */
                onScroll: function() {
                    let that = this
                    let percent = this.progress_bar_percent
                    if (document.body.scrollHeight <= $(document).scrollTop() + window.innerHeight) {
                        percent = 100
                    } else {
                        // Round percent to 2 decimal place.
                        percent = Math.round((window.pageYOffset / document.body.scrollHeight) * 10000) / 100
                    }

                    if (!that.modal_open) {
                        this.progress_bar_percent = percent
                    }
                },

                /**
                 * Process any URL defined inputs.
                 */
                parse_url: function() {
                    if (window.location.hash) {
                        this.listening_url = false
                        let params = Optionplan.get_url_params()
                        // Support old URL pattern.
                        let legacy_params = {
                            employee_equity_aggression: 'salary_allocation_level',
                            postmoney_series_a_valuation: 'pre_funding_valuation',
                            amount_raised_series_a: 'initial_amount_raised',
                            equity_allocated_pre_a_employees: 'initial_equity_allocated',
                            series_a_valuation_post_funding: 'series_a_valuation_post_funding',
                            executive_ownership_cxo: 'equity_cxo_individual',
                            executive_ownership_vp: 'equity_vp_individual',
                            hiring_plans__employee__engineering_devops_ml__counts__individual: 'hires_engineering_devops_ml_individual',
                            hiring_plans__employee__engineering_devops_ml__counts__lead: 'hires_engineering_devops_ml_lead',
                            hiring_plans__employee__engineering_devops_ml__counts__director: 'hires_engineering_devops_ml_director',
                            hiring_plans__employee__engineering_devops_ml__salaries__individual: 'salary_engineering_devops_ml_individual',
                            hiring_plans__employee__engineering_devops_ml__salaries__lead: 'salary_engineering_devops_ml_lead',
                            hiring_plans__employee__engineering_devops_ml__salaries__director: 'salary_engineering_devops_ml_director',
                            hiring_plans__employee__marketing_sales_success_hr_finance__counts__individual: 'hires_marketing_sales_success_hr_finance_individual',
                            hiring_plans__employee__marketing_sales_success_hr_finance__counts__lead: 'hires_marketing_sales_success_hr_finance_lead',
                            hiring_plans__employee__marketing_sales_success_hr_finance__counts__director: 'hires_marketing_sales_success_hr_finance_director',
                            hiring_plans__employee__marketing_sales_success_hr_finance__salaries__individual: 'salary_marketing_sales_success_hr_finance_individual',
                            hiring_plans__employee__marketing_sales_success_hr_finance__salaries__lead: 'salary_marketing_sales_success_hr_finance_lead',
                            hiring_plans__employee__marketing_sales_success_hr_finance__salaries__director: 'salary_marketing_sales_success_hr_finance_director',
                            hiring_plans__employee__inside_sales_support__counts__individual: 'hires_inside_sales_support_individual',
                            hiring_plans__employee__inside_sales_support__counts__lead: 'hires_inside_sales_support_lead',
                            hiring_plans__employee__inside_sales_support__counts__director: 'hires_inside_sales_support_director',
                            hiring_plans__employee__inside_sales_support__salaries__individual: 'salary_inside_sales_support_individual',
                            hiring_plans__employee__inside_sales_support__salaries__lead: 'salary_inside_sales_support_lead',
                            hiring_plans__employee__inside_sales_support__salaries__director: 'salary_inside_sales_support_director',
                            hiring_plans__executive__cxo__count: 'hires_cxo_individual',
                            hiring_plans__executive__vp__count: 'hires_vp_individual',
                        }

                        for (let i in params) {
                            // Map old param names to new ones.
                            if (typeof legacy_params[i] != 'undefined') {
                                params[legacy_params[i]] = params[i]
                                delete params[i]
                                i = legacy_params[i]
                            }

                            // Regular inputs.
                            if (typeof this.inputs[i] != 'undefined') {
                                // Legacy support for OP V1.
                                if (i == 'expected_funding_rounds_pre_exit' && params[i] == 'series-a-only') {
                                    params[i] = 'series-a'
                                }
                                // Express calculation percentages (fractions) as normal percentages.
                                if (i == 'initial_equity_allocated') {
                                    params[i] = params[i] / 100
                                }
                                // Swap out decimal points in the URL.
                                else if (i.substring(0, 7) === 'equity_') {
                                    params[i] = params[i].replace('-', '.')
                                }
                                if ($.isNumeric(params[i])) {
                                    params[i] = parseFloat(params[i]);
                                }
                                this.inputs[i] = params[i]
                            }

                            // Hiring plans.
                            if (i.substring(0, 14) == 'hiring_plans__') {
                                let parts = i.split('__')
                                if (parts[1] == 'executive' && parts.length == 4 && typeof this.inputs[parts[0]][parts[1]][parts[2]][parts[3]] != 'undefined' && parseInt(params[i]) > 0) {
                                    this.inputs[parts[0]][parts[1]][parts[2]][parts[3]] = params[i]
                                } else if (parts[1] == 'employee' && parts.length == 5 && typeof this.inputs[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]] != 'undefined') {
                                    this.inputs[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]] = params[i]
                                }
                            }
                        }
                        this.listening_url = true
                    }
                },

                /**
                 * Show the Export URL Box.
                 */
                toggle_export_url: function() {
                    this.export_url_visible = !this.export_url_visible
                    if (this.export_url_visible) {
                        this.$nextTick(function() {
                            $('#export_url_input').select().on('focus', function() {
                                $(this).select()
                            })
                        })
                    }
                },

                /**
                 * Show the sidebar Export box.
                 */
                toggle_export_url_sidebar: function() {
                    this.export_url_sidebar_visible = !this.export_url_sidebar_visible
                    if (this.export_url_sidebar_visible) {
                        this.$nextTick(function() {
                            $('#export_url_input_sidebar').select().on('focus', function() {
                                $(this).select()
                            })
                        })
                    }
                },

                /**
                 * Change the active step in the embedded calculator.
                 */
                embed_goto: function(step) {
                    this.embed.step = step
                },

                /**
                 * Redo the trig to draw the diagonal slider.
                 */
                recalculate_triangular_slider: function($slider, model, selector) {
                    let slider_width = $slider.width()
                    let current_value;
                    if (typeof slider_width !== 'undefined') {
                        let last_slider_width = $slider.data('slider-width')
                        if (slider_width !== last_slider_width) {
                            $slider.data('slider-width', slider_width)
                            let sin = this.diagonal.sin
                            let that = this
                            let $ticks = $('.vue-slider-mark', $slider)
                            if (typeof current_value === 'undefined') {
                                current_value = model
                            }
                            let $slider_wrapper_before = $slider.find('.slider__wrapper-before')

                            // Loop through the piecewise setting the height based on trigonometry.
                            $ticks.each(function(index, element) {
                                let $el = $(element)
                                let left = $el.position().left
                                let hypotenuse = left >= 0 ? left : 0
                                let opposite = hypotenuse * sin
                                let height = Math.round(opposite)

                                let $step = $el.find('.vue-slider-mark-step')
                                // no idea why but .css does nothing
                                $step.attr('style', `height: ${height + 28}px !important`)

                                let $label = $el.find('.vue-slider-mark-label')
                                // no idea why but .css does nothing
                                $label.attr('style', `padding-top: ${height + 10}px !important`)

                                // Mark as active if this tick is selected.
                                $el.removeClass('active')
                                if (index + 1 == current_value) {
                                    $el.addClass('active')
                                }

                                // Last tick.
                                if (index + 1 == $ticks.length) {
                                    // Set the width of the horizontal line based on the last tick line.
                                    $slider_wrapper_before.attr('style', `width: ${that.is_large_screen ? Math.round(left) + 6 : Math.round(left) + 1}px !important`)
                                }
                            })
                        }
                    }
                    $slider.css('opacity', 1)
                },

                /**
                 * Reset input fields.
                 *
                 * Can't just copy over the inputs object as that loses watchers etc.
                 */
                reset_inputs: function(type, key_types_array) {
                    let all = type === 'all'
                    key_types_array = key_types_array || ['hires', 'equity', 'salary']
                    let key = null

                    // Standard inputs.
                    if (all) {
                        for (let i in this.inputs) {
                            if (i != 'hiring_plans') {
                                this.inputs[i] = OptionplanConfig.input_defaults[this.mode][i]
                            }
                        }
                    }

                    // Executive hiring plans.
                    if (all || type === 'hiring_plans_executive') {
                        for (let role in this.inputs.hiring_plans.executive) {
                            if (key_types_array.includes('hires')) {
                                key = 'hires_' + role + '_individual';
                                this.input_defaults[key] = OptionplanConfig.input_defaults[this.mode][key];
                                this.inputs[key] = OptionplanConfig.input_defaults[this.mode][key];
                            }
                            if (key_types_array.includes('equity')) {
                                key = 'equity_' + role + '_individual';
                                this.input_defaults[key] = this.make_percentage(OptionplanConfig.input_defaults[this.mode][key], 2);
                                this.inputs[key] = this.make_percentage(OptionplanConfig.input_defaults[this.mode][key], 2);
                            }
                        }
                    }

                    // Non executive hiring plans.
                    if (all || type === 'hiring_plans_employee') {
                        for (let sector in this.inputs.hiring_plans.employee) {
                            for (let role in this.inputs.hiring_plans.employee[sector].counts) {
                                if (key_types_array.includes('hires')) {
                                    key = 'hires_' + sector + '_' + role
                                    this.input_defaults[key] = OptionplanConfig.input_defaults[this.mode][key]
                                    this.inputs[key] = OptionplanConfig.input_defaults[this.mode][key]
                                }

                                if (key_types_array.includes('equity')) {
                                    key = 'equity_' + sector + '_' + role
                                    this.input_defaults[key] = this.make_percentage(OptionplanConfig.input_defaults[this.mode][key], 2)
                                    this.inputs[key] = this.make_percentage(OptionplanConfig.input_defaults[this.mode][key], 2)
                                }

                                if (key_types_array.includes('salary')) {
                                    key = 'salary_' + sector + '_' + role
                                    this.input_defaults[key] = OptionplanConfig.input_defaults[this.mode][key]
                                    this.inputs[key] = OptionplanConfig.input_defaults[this.mode][key]
                                }
                            }
                        }
                    }

                    // Hide assumptions.
                    this.modals.assumptions_active = false
                    this.set_percentage_input_suffix_position()
                },

                /**
                 * Input mask to ensure number is given.
                 */
                input_range_numeric: function(e, min, max, precision) {
                    // Allow enter, but blur the input target first
                    if (e.which == 13) {
                        e.target.blur();
                        return
                    }
                    // Always allow backspace, tab and arrows.
                    if (e.which == 8 || e.which == 46 || e.which == 37 || e.which == 39 || e.which == 9) {
                        return
                    }

                    // Work out what the value will be. (element value doesn't change until after this event).
                    let new_value = e.target.value.slice(0, e.target.selectionStart) + e.key + e.target.value.slice(e.target.selectionEnd, e.target.value.length)

                    // Build the pattern based on the precision required.
                    let pattern = '^'
                    if (min < 0) {
                        pattern = pattern + '-?'
                    }
                    pattern = pattern + '[0-9]+'
                    if (precision > 0) {
                        pattern = pattern + '(\\.?'
                        for (let x = 0; x < precision; x++) {
                            pattern = pattern + '[0-9]?'
                        }
                        pattern = pattern + ')?'
                    }

                    pattern = new RegExp(pattern + '$')
                    if (!pattern.test(new_value)) {
                        e.preventDefault()
                    }

                    // The number looks good but we need to check it is not outside the allowed range.
                    let number = parseFloat(new_value)
                    if (number < min || number > max) {
                        e.preventDefault()
                    }
                },

                /**
                 * Hide the input suffix (on focus).
                 */
                hide_suffix: function(e) {
                    $('.suffix', $(e.target.parentElement)).hide()
                },

                /**
                 * Show the input suffix (on blur).
                 */
                show_suffix: function(e) {
                    this.position_suffix(null, e.target)
                },

                /**
                 * Show the input suffix (on blur).
                 */
                position_suffix: function(index, element) {
                    let $element = $(element)
                    let $wrapper = $element.parent()
                    let $value = $('.value', $wrapper)
                    $value.text($element.val())
                    $('.suffix', $wrapper).css({ left: 10 + $value.width() }).show()
                },

                /**
                 * Set the country to the users country (based on IP) unless they've changed the value.
                 */
                user_location_change: function() {
                    let params = Optionplan.get_url_params()

                    // Set the default country to the user's if we know it and the user hasn't change it.
                    if (this.inputs.employee_country == this.input_defaults.employee_country
                        && this.inputs.employee_country != params.employee_country) {
                        let country = $.cookie('user-country')
                        if (country && country.length) {
                            // Translate country codes to codes we deal with.
                            let countries = {
                                GB: 'uk',
                                UK: 'uk',
                                FR: 'france',
                                US: 'us',
                                DK: 'denmark',
                                FI: 'finland',
                                DE: 'germany',
                                IE: 'ireland',
                                NL: 'netherlands',
                                ES: 'spain',
                                SE: 'sweden',
                                AU: 'australia',
                                AT: 'austria',
                                BE: 'belgium',
                                CA: 'canada',
                                CZ: 'czech-republic',
                                EE: 'estonia',
                                IL: 'israel',
                                IT: 'italy',
                                NO: 'norway',
                                PL: 'poland',
                                PT: 'portugal',
                                CH: 'switzerland',
                            }

                            if (typeof countries[country] != 'undefined') {
                                this.input_defaults.employee_country = countries[country]
                                this.inputs.employee_country = countries[country]
                            }
                        }
                    }
                },

                /**
                 * Set the appropriate classes on flags when the selected country changes.
                 */
                activate_flag: function() {
                    $('#country_select input+label .grayscale').removeClass('grayscale-off')
                    $('#country_select input[value=' + this.inputs.employee_country + ']+label .grayscale').addClass('grayscale-off')
                },

                /**
                 * Update the browser URL and export URLs with the latest data.
                 */
                update_url: function() {
                    let working_copy = {}
                    $.extend(true, working_copy, this.inputs)
                    working_copy.mode = this.mode

                    // Hiring plans.
                    for (let level in working_copy.hiring_plans) {
                        for (let sector in this.sectors) {
                            for (let role in this.roles) {
                                // Equity percentages with decimal points swapped for URL friendly hyphens, e.g. "equity_inside_sales_support_lead=50-5".
                                let key = 'equity_' + sector + '_' + role
                                if (typeof working_copy[key] != 'undefined' && this.input_defaults[key] != working_copy[key]) {
                                    if (!isNaN(working_copy[key])) {
                                        working_copy[key] = ('' + this.round(working_copy[key], 2)).replace('.', '-')
                                    }
                                } else {
                                    delete working_copy[key]
                                }

                                if (typeof working_copy.hiring_plans[level][sector] != 'undefined'
                                    && typeof working_copy.hiring_plans[level][sector].counts[role] != 'undefined') {
                                    // Number of hires by role.
                                    key = 'hires_' + sector + '_' + role
                                    if (typeof working_copy[key] == 'undefined' || this.input_defaults[key] == working_copy[key]) {
                                        delete working_copy[key]
                                    }

                                    // Salary by role.
                                    key = 'salary_' + sector + '_' + role
                                    if (typeof working_copy[key] == 'undefined' || this.input_defaults[key] == working_copy[key]) {
                                        delete working_copy[key]
                                    }
                                }
                            }
                        }
                    }

                    delete working_copy.hiring_plans

                    // Remove inputs that are still defaults.
                    for (let i in working_copy) {
                        // Always add country.
                        if (i == 'employee_country') {
                            continue
                        }
                        // Allways add funding rounds.
                        else if (i == 'expected_funding_rounds_pre_exit') {
                            continue
                        }

                        if (working_copy[i] == this.input_defaults[i]) {
                            delete working_copy[i]
                        }
                    }

                    // Work-around for an nginx rule that doesn't like "." in the URL.
                    if (typeof working_copy.initial_equity_allocated != 'undefined') {
                        working_copy.initial_equity_allocated = this.round(working_copy.initial_equity_allocated * 100)
                    }

                    let hash = $.param(working_copy)
                    window.location.hash = hash ? hash : '_'
                    this.export_inputs = btoa(hash)
                    this.export_url = window.location.origin + window.location.pathname + '#b64-' + this.export_inputs

                    this.track_changed(working_copy)
                },

                /**
                 * Send events to Google Analytics when inputs change.
                 */
                track_changed: function (inputs) {
                    if (typeof gtag !== 'undefined') {
                        let send_page_view = false
                        if (this.initial_inputs_changed) {
                            for (let i in inputs) {
                                if (typeof this.inputs_tracked[i] == 'undefined' || inputs[i] !== this.inputs_tracked[i]) {
                                    send_page_view = true
                                    let event = {
                                        'event_category': 'Option Plan',
                                        'event_label': 'Inputs Change',
                                        'page_location': appendIpuiToURL(window.location.href)
                                    }
                                    event[i] = inputs[i]
                                    gtag('event', 'value_change', event);
                                }
                            }
                        }
                        this.initial_inputs_changed = true
                        if (send_page_view) {
                            let element = document.querySelector('[data-content-group-name]')
                            let ga4config = {}
                            if (element && element.dataset.contentGroupName) {
                                ga4config['content_group'] = element.dataset.contentGroupName
                            }
                            ga4config['page_location'] = appendIpuiToURL(window.location.href)
                            window.gtag('event', 'page_view', ga4config)
                        }
                    }

                    this.inputs_tracked = inputs
                },

                /**
                 * Adjust the "%" suffix on percentage inputs.
                 */
                set_percentage_input_suffix_position: function() {
                    this.$nextTick(function() {
                        // Position input suffixes.
                        $('.percentage input').each(this.position_suffix)
                    })
                },

                /**
                 * Update the piecewise display for en / us defaults slider.
                 *
                 * @todo move this to generic slider wrapper class later.
                 */
                update_slider_piecewise_eu_us: function(slider, eu_benchmark, us_benchmark) {
                    this.$nextTick(function() {
                        let $slider = $('.slider__' + slider)
                        $('.vue-slider-mark-label', $slider).each(function(index, element) {
                            let $el = $(element)
                            let value = $el.text().replace(/\D/g, '')
                            let eu = eu_benchmark / 1000000
                            let us = us_benchmark / 1000000

                            // Show the Europe and US benchmarks.
                            if (value == eu / 1000000) {
                                $el.text('Europe $' + eu / 1000000 + 'm').addClass('default')
                            } else if (value == us / 1000000) {
                                $el.text('US $' + us + 'm').addClass('visible')
                            } else {
                                $el.text('')
                                // Make sure all others are hidden.
                                $el.removeClass('visible default')
                            }
                        })
                    })
                },

                /**
                 * Update the inputs and defaults for the desired "mode".
                 */
                change_mode: function(mode, force, parse_url) {
                    if (mode === this.mode && typeof force == 'undefined') {
                        return
                    }
                    this.mode = mode

                    // doesn't redirect if it's the same url
                    this.goto_allowed_hash(mode)

                    // Clone input defaults so that they're not changed after use.
                    let inputs = {}
                    let options = {}
                    let defaults = {}
                    $.extend(true, inputs, OptionplanConfig.input_defaults[mode])
                    $.extend(true, defaults, OptionplanConfig.input_defaults[mode])
                    $.extend(true, options, OptionplanConfig.input_options)

                    // Swap out the current values and defaults.
                    for (let i in inputs) {
                        if (i == 'employee_country') {
                            // Don't change the input value as that's what was set to trigger this function.
                            continue
                        }
                        this.inputs[i] = inputs[i]
                        this.input_defaults[i] = defaults[i]
                    }

                    // Update equity allocations.
                    for (let i in this.input_options.equity_allocations) {
                        this.input_options.equity_allocations[i] = options.equity_allocations[i][mode]
                    }

                    // This is kind of crazy but we need values from the url in order to set the correct defaults...
                    if (parse_url) {
                        this.parse_url()
                    }

                    // ... But when we reset the computed inputs we are wiping over the inputs we just parsed from the url...
                    this.reset_computed_inputs(this.equity_allocation.id, this.inputs.salary_allocation_level)

                    // ... So we need to parse the url values again to update them correctly
                    if (parse_url) {
                        this.parse_url()
                    }

                    this.init_sliders()
                },

                /**
                 * Some input defaults are computed. This is called when something changes that will affect them.
                 */
                reset_computed_inputs: function(equity_allocation_id, salary_allocation_id, resets) {
                    resets = typeof resets == 'undefined' ? [] : resets
                    let equity_allocation = this.input_options.equity_allocations[equity_allocation_id]

                    for (let level in this.inputs.hiring_plans) {
                        for (let sector in equity_allocation[level]) {
                            for (let role in equity_allocation[level][sector]) {
                                // Equity.
                                if (!resets.length || $.inArray('equity', resets) > -1) {
                                    let key = 'equity_' + sector + '_' + role
                                    this.input_defaults[key] = this.make_percentage(equity_allocation[level][sector][role], 2)
                                    this.inputs[key] = this.input_defaults[key]
                                }

                                // Hires.
                                if (!resets.length || $.inArray('hires', resets) > -1) {
                                    let key = 'hires_' + sector + '_' + role
                                    this.input_defaults[key] = this.input_defaults.hiring_plans[level][sector].counts[role]
                                    this.inputs[key] = this.input_defaults[key]
                                }

                                // Salary.
                                if (!resets.length || $.inArray('salary', resets) > -1) {
                                    let key = 'salary_' + sector + '_' + role
                                    if (typeof this.input_defaults.hiring_plans[level][sector].salaries != 'undefined') {
                                        this.input_defaults[key] = this.input_defaults.hiring_plans[level][sector].salaries[salary_allocation_id][role]
                                    } else {
                                        this.input_defaults[key] = 0
                                    }
                                    this.inputs[key] = this.input_defaults[key]
                                }
                            }
                        }
                    }
                },
                login: function(login_type) {
                    const path = window.location.href.slice(window.location.origin.length).trim()
                    let url = encodeURI(path)
                    url = url.replace(/#/g, '%23')
                    url = url.replace(/&/g, '%26')
                    window.location.href = `/index-press/login/${login_type}?final_url=${url}`
                },
                goto_allowed_hash: function(mode = null) {
                    const [uahKey] = getUahAndExpiryKeys(mode)
                    if (!uahKey) {
                        // default to the base url
                        window.location.hash = ''
                        return
                    }

                    const uah = localStorage.getItem(uahKey)

                    if (!uah) return

                    const uahUnencoded = atob(uah)

                    setTimeout(() => {
                        // check if the current hash is the same as the uah
                        if (window.location.hash === uahUnencoded) {
                            // if it is, do nothing
                            return
                        }

                        if (!userLoggedInCookie()) {
                            window.location.hash = uahUnencoded
                        }
                        // append the current &mode=seed|venture to the url

                        let default_mode = Optionplan.get_mode_from_url()
                        // We have to call parse_url from within change_mode to make sure the correct defaults AND url params get loaded.
                        this.change_mode(default_mode, true, true)
                    }, 50)
                },
            },
        })
    },

    /**
     * Get the URL parameters.
     */
    get_url_params: function() {
        let params = window.location.hash
        // Is the URL base64 encoded?
        if (params.substr(0, 5) == '#b64-') {
            params = atob(params.substr(5))
        }

        params = params.replace(/(^\?|^#)/, '').split('&').map(function(n) {
            return n = n.split('='), this[n[0]] = n[1], this
        }.bind({}))[0]

        return params
    },

    /**
     * Get a value from the URL.
     */
    get_value_from_url: function(value) {
        let url_params = this.get_url_params()
        return typeof url_params[value] == 'undefined' ? null : url_params[value]
    },

    /**
     * Get the mode from the URL.
     */
    get_mode_from_url: function() {
        let mode = Optionplan.get_value_from_url('mode')
        return mode != null ? mode : 'seed'
    }
}

export { Optionplan }

// Initialise empty flat config as the keys need to exist before Vue is instantiated for Vue to bind them.
$.each(['seed', 'venture'], function(index, mode) {
    $.each(OptionplanConfig.sectors, function(sector, data) {
        $.each(OptionplanConfig.roles, function(role, data2) {
            $.each(['employee', 'executive'], function(index2, job_level) {
                // Employee defaults.
                if (
                    OptionplanConfig.input_defaults[mode]['hiring_plans'][job_level].hasOwnProperty(sector)
                    && OptionplanConfig.input_defaults[mode]['hiring_plans'][job_level][sector]['counts'].hasOwnProperty(role)
                ) {
                    var equity_level = OptionplanConfig.input_defaults[mode].equity_allocation_level,
                        salary_level = OptionplanConfig.input_defaults[mode].salary_allocation_level;

                    // Hires.
                    OptionplanConfig.input_defaults[mode]['hires_' + sector + '_' + role] = OptionplanConfig.input_defaults[mode]['hiring_plans'][job_level][sector]['counts'][role];

                    // Salaries.
                    if (job_level == 'employee') {
                        OptionplanConfig.input_defaults[mode]['salary_' + sector + '_' + role] = OptionplanConfig.input_defaults[mode]['hiring_plans'][job_level][sector]['salaries'][salary_level][role];
                    }

                    // Equities.
                    if (
                        OptionplanConfig.input_options.equity_allocations[equity_level][mode][job_level].hasOwnProperty(sector)
                        && OptionplanConfig.input_options.equity_allocations[equity_level][mode][job_level][sector].hasOwnProperty(role)
                    ) {
                        OptionplanConfig.input_defaults[mode]['equity_' + sector + '_' + role] = OptionplanConfig.input_options.equity_allocations[equity_level][mode][job_level][sector][role];
                    }
                }
            });
        });
    });
});
